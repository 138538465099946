export const phoneCountryCodes: Array<{ code: string; name: string }> = [
  {
    code: '60',
    name: 'Malaysia',
  },
  {
    code: '65',
    name: 'Singapore',
  },
  {
    code: '93',
    name: 'Afghanistan',
  },
  {
    code: '355',
    name: 'Albania',
  },
  {
    code: '213',
    name: 'Algeria',
  },
  {
    code: '376',
    name: 'Andorra',
  },
  {
    code: '244',
    name: 'Angola',
  },
  {
    code: '54',
    name: 'Argentina',
  },
  {
    code: '374',
    name: 'Armenia',
  },
  {
    code: '297',
    name: 'Aruba',
  },
  {
    code: '61',
    name: 'Australia',
  },
  {
    code: '375',
    name: 'Belarus',
  },
  {
    code: '32',
    name: 'Belgium',
  },
  {
    code: '501',
    name: 'Belize',
  },
  {
    code: '229',
    name: 'Benin',
  },
  {
    code: '591',
    name: 'Bolivia',
  },
  {
    code: '387',
    name: 'Bosnia and Herzegovina',
  },
  {
    code: '267',
    name: 'Botswana',
  },
  {
    code: '55',
    name: 'Brazil',
  },
  {
    code: '359',
    name: 'Bulgaria',
  },
  {
    code: '226',
    name: 'Burkina Faso',
  },
  {
    code: '257',
    name: 'Burundi',
  },
  {
    code: '237',
    name: 'Cameroon',
  },
  {
    code: '238',
    name: 'Cape Verde',
  },
  {
    code: '236',
    name: 'Central African Republic',
  },
  {
    code: '235',
    name: 'Chad',
  },
  {
    code: '86',
    name: 'China',
  },
  {
    code: '269',
    name: 'Comoros',
  },
  {
    code: '242',
    name: 'Congo',
  },
  {
    code: '506',
    name: 'Costa Rica',
  },
  {
    code: '385',
    name: 'Croatia',
  },
  {
    code: '599',
    name: 'Curaçao',
  },
  {
    code: '357',
    name: 'Cyprus',
  },
  {
    code: '420',
    name: 'Czech Republic',
  },
  {
    code: '243',
    name: 'Democratic Republic of the Congo',
  },
  {
    code: '45',
    name: 'Denmark',
  },
  {
    code: '253',
    name: 'Djibouti',
  },
  {
    code: '593',
    name: 'Ecuador',
  },
  {
    code: '20',
    name: 'Egypt',
  },
  {
    code: '503',
    name: 'El Salvador',
  },
  {
    code: '240',
    name: 'Equatorial Guinea',
  },
  {
    code: '291',
    name: 'Eritrea',
  },
  {
    code: '372',
    name: 'Estonia',
  },
  {
    code: '268',
    name: 'Eswatini',
  },
  {
    code: '251',
    name: 'Ethiopia',
  },
  {
    code: '500',
    name: 'Falkland Islands',
  },
  {
    code: '298',
    name: 'Faroe Islands',
  },
  {
    code: '358',
    name: 'Finland',
  },
  {
    code: '33',
    name: 'France',
  },
  {
    code: '594',
    name: 'French Guiana',
  },
  {
    code: '241',
    name: 'Gabon',
  },
  {
    code: '220',
    name: 'Gambia',
  },
  {
    code: '49',
    name: 'Germany',
  },
  {
    code: '233',
    name: 'Ghana',
  },
  {
    code: '350',
    name: 'Gibraltar',
  },
  {
    code: '30',
    name: 'Greece',
  },
  {
    code: '299',
    name: 'Greenland',
  },
  {
    code: '590',
    name: 'Guadeloupe',
  },
  {
    code: '502',
    name: 'Guatemala',
  },
  {
    code: '224',
    name: 'Guinea',
  },
  {
    code: '245',
    name: 'Guinea-Bissau',
  },
  {
    code: '592',
    name: 'Guyana',
  },
  {
    code: '509',
    name: 'Haiti',
  },
  {
    code: '504',
    name: 'Honduras',
  },
  {
    code: '36',
    name: 'Hungary',
  },
  {
    code: '354',
    name: 'Iceland',
  },
  {
    code: '91',
    name: 'India',
  },
  {
    code: '62',
    name: 'Indonesia',
  },
  {
    code: '98',
    name: 'Iran',
  },
  {
    code: '353',
    name: 'Ireland',
  },
  {
    code: '39',
    name: 'Italy',
  },
  {
    code: '225',
    name: 'Ivory Coast',
  },
  {
    code: '81',
    name: 'Japan',
  },
  {
    code: '254',
    name: 'Kenya',
  },
  {
    code: '383',
    name: 'Kosovo',
  },
  {
    code: '371',
    name: 'Latvia',
  },
  {
    code: '266',
    name: 'Lesotho',
  },
  {
    code: '231',
    name: 'Liberia',
  },
  {
    code: '218',
    name: 'Libya',
  },
  {
    code: '423',
    name: 'Liechtenstein',
  },
  {
    code: '370',
    name: 'Lithuania',
  },
  {
    code: '352',
    name: 'Luxembourg',
  },
  {
    code: '261',
    name: 'Madagascar',
  },
  {
    code: '265',
    name: 'Malawi',
  },
  {
    code: '223',
    name: 'Mali',
  },
  {
    code: '356',
    name: 'Malta',
  },
  {
    code: '596',
    name: 'Martinique',
  },
  {
    code: '222',
    name: 'Mauritania',
  },
  {
    code: '230',
    name: 'Mauritius',
  },
  {
    code: '52',
    name: 'Mexico',
  },
  {
    code: '373',
    name: 'Moldova',
  },
  {
    code: '377',
    name: 'Monaco',
  },
  {
    code: '382',
    name: 'Montenegro',
  },
  {
    code: '212',
    name: 'Morocco',
  },
  {
    code: '258',
    name: 'Mozambique',
  },
  {
    code: '95',
    name: 'Myanmar',
  },
  {
    code: '264',
    name: 'Namibia',
  },
  {
    code: '31',
    name: 'Netherlands',
  },
  {
    code: '64',
    name: 'New Zealand',
  },
  {
    code: '505',
    name: 'Nicaragua',
  },
  {
    code: '227',
    name: 'Niger',
  },
  {
    code: '234',
    name: 'Nigeria',
  },
  {
    code: '389',
    name: 'North Macedonia',
  },
  {
    code: '47',
    name: 'Norway',
  },
  {
    code: '92',
    name: 'Pakistan',
  },
  {
    code: '507',
    name: 'Panama',
  },
  {
    code: '595',
    name: 'Paraguay',
  },
  {
    code: '63',
    name: 'Philippines',
  },
  {
    code: '48',
    name: 'Poland',
  },
  {
    code: '351',
    name: 'Portugal',
  },
  {
    code: '40',
    name: 'Romania',
  },
  {
    code: '250',
    name: 'Rwanda',
  },
  {
    code: '290',
    name: 'Saint Helena',
  },
  {
    code: '508',
    name: 'Saint Pierre and Miquelon',
  },
  {
    code: '378',
    name: 'San Marino',
  },
  {
    code: '239',
    name: 'São Tomé and Príncipe',
  },
  {
    code: '221',
    name: 'Senegal',
  },
  {
    code: '381',
    name: 'Serbia',
  },
  {
    code: '248',
    name: 'Seychelles',
  },
  {
    code: '232',
    name: 'Sierra Leone',
  },
  {
    code: '421',
    name: 'Slovakia',
  },
  {
    code: '386',
    name: 'Slovenia',
  },
  {
    code: '252',
    name: 'Somalia',
  },
  {
    code: '27',
    name: 'South Africa',
  },
  {
    code: '82',
    name: 'South Korea',
  },
  {
    code: '34',
    name: 'Spain',
  },
  {
    code: '94',
    name: 'Sri Lanka',
  },
  {
    code: '249',
    name: 'Sudan',
  },
  {
    code: '597',
    name: 'Suriname',
  },
  {
    code: '46',
    name: 'Sweden',
  },
  {
    code: '255',
    name: 'Tanzania',
  },
  {
    code: '66',
    name: 'Thailand',
  },
  {
    code: '228',
    name: 'Togo',
  },
  {
    code: '216',
    name: 'Tunisia',
  },
  {
    code: '90',
    name: 'Turkey',
  },
  {
    code: '256',
    name: 'Uganda',
  },
  {
    code: '380',
    name: 'Ukraine',
  },
  {
    code: '44',
    name: 'United Kingdom',
  },
  {
    code: '1',
    name: 'United States/Canada',
  },
  {
    code: '598',
    name: 'Uruguay',
  },
  {
    code: '84',
    name: 'Vietnam',
  },
  {
    code: '260',
    name: 'Zambia',
  },
  {
    code: '263',
    name: 'Zimbabwe',
  },
];
