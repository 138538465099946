import { salespersonRole } from '@app/constants/common';
import { UserContext } from '@app/context/UserContext';
import { StaffRoleEnum } from '@app/types/staffTypes';
import { isAuthorized } from '@app/utils/utils';
import { useContext, useMemo } from 'react';

export const useUserPermission = () => {
  const { user } = useContext(UserContext);

  const checkRole = (role: StaffRoleEnum[] | undefined) => isAuthorized(user, role);

  return useMemo(
    () => ({
      // Park
      canEditPark: checkRole([StaffRoleEnum.Admin]),

      // Lot
      canEditLot: checkRole([StaffRoleEnum.Admin]),
      canEditNomineeAndBeneficiary: checkRole([StaffRoleEnum.Manager, StaffRoleEnum.Salesperson]),

      // Miscellaneous product
      canNavigateToMiscProductPage: checkRole([
        StaffRoleEnum.Admin,
        StaffRoleEnum.SeniorSeniorManager,
        StaffRoleEnum.SeniorManager,
        StaffRoleEnum.Manager,
        StaffRoleEnum.Finance,
      ]),
      canEditMiscProduct: checkRole([StaffRoleEnum.Admin]),

      // Staff
      canEditStaff: checkRole([StaffRoleEnum.Admin]),
      canExportStaff: checkRole([
        StaffRoleEnum.Admin,
        StaffRoleEnum.SeniorSeniorManager,
        StaffRoleEnum.SeniorManager,
        StaffRoleEnum.Manager,
      ]),

      // Agent
      canEditAgent: checkRole([StaffRoleEnum.Admin]),
      canExportAgent: checkRole([
        StaffRoleEnum.Admin,
        StaffRoleEnum.SeniorSeniorManager,
        StaffRoleEnum.SeniorManager,
        StaffRoleEnum.Manager,
      ]),

      // Promotion
      canEditPromotion: checkRole([StaffRoleEnum.Admin]),

      // Company
      canNavigateToCompanyPage: checkRole([StaffRoleEnum.Admin]),
      canEditCompany: checkRole([StaffRoleEnum.Admin]),

      // Approval Matrix
      canNavigateToApprovalMatrixPage: checkRole([StaffRoleEnum.Admin]),

      // Customer
      canEditCustomerName: checkRole([StaffRoleEnum.Admin]),
      canExportCustomer: checkRole([
        StaffRoleEnum.Admin,
        StaffRoleEnum.SeniorSeniorManager,
        StaffRoleEnum.SeniorManager,
        StaffRoleEnum.Manager,
      ]),

      // Sale
      canEditSale: checkRole(salespersonRole),
      canExportSale: checkRole([
        StaffRoleEnum.Admin,
        StaffRoleEnum.Finance,
        StaffRoleEnum.SeniorSeniorManager,
        StaffRoleEnum.SeniorManager,
        StaffRoleEnum.Manager,
      ]),
      canUploadAdditionalDocuments: checkRole([StaffRoleEnum.Admin]),
      canEditBackdatedSale: checkRole([StaffRoleEnum.Admin]),

      // Payment
      canEditPayment: checkRole([StaffRoleEnum.Admin, StaffRoleEnum.Finance]),
      canExportPayment: checkRole([
        StaffRoleEnum.Admin,
        StaffRoleEnum.Finance,
        StaffRoleEnum.SeniorSeniorManager,
        StaffRoleEnum.SeniorManager,
        StaffRoleEnum.Manager,
      ]),
      canUploadNewPaymentProof: checkRole(salespersonRole),
      canRequestToWaiveInterest: checkRole(salespersonRole),
      canSettlePayment: checkRole(salespersonRole),
      canCreateBackdatedPayment: checkRole([StaffRoleEnum.Admin]),

      // Payment verification
      canViewPaymentVerification: checkRole([
        StaffRoleEnum.Finance,
        StaffRoleEnum.SeniorSeniorManager,
        StaffRoleEnum.SeniorManager,
        StaffRoleEnum.Manager,
        StaffRoleEnum.Salesperson,
      ]),

      // Refund
      canEditRefund: checkRole([StaffRoleEnum.Finance]),
      canExportRefund: checkRole([StaffRoleEnum.Admin]),

      // Commissions
      canNavigateToCommissionPage: checkRole([StaffRoleEnum.Admin]),

      // Product Value
      canEditProductValue: checkRole([StaffRoleEnum.Admin]),
      canEditTiersAndOthers: checkRole([StaffRoleEnum.Admin]),

      // Targets
      canEditTarget: checkRole([StaffRoleEnum.Admin]),

      // Channels
      canEditChannel: checkRole([StaffRoleEnum.Admin]),
    }),
    [user],
  );
};
