import { gql } from '@apollo/client';

export const GET_PAYMENTS = gql`
  query Payments($input: GetPaymentsInput!) {
    payments(input: $input) {
      payments {
        id
        orderNo
        paymentMethod
        paymentType
        paymentDuration
        purchaseType
        dueDate
        paymentProof
        status
        parentStatus
        amount
        sale {
          id
          years
          months
          paymentType
          isDeferred
          customer {
            id
            firstName
            lastName
          }
          lot {
            id
            name
          }
        }
        commission {
          id
          status
        }
        outstanding {
          amount
        }
        createdAt
      }
      paginationInfo {
        totalItems
        limit
        currentPage
        totalPages
        nextPage
        prevPage
        hasNextPage
        hasPrevPage
      }
    }
  }
`;

export const GET_PAYMENT = gql`
  query Payment($id: ID!) {
    payment(id: $id) {
      id
      orderNo
      receipt
      status
    }
  }
`;

export const GET_PAYMENT_ENTRIES = gql`
  query PaymentEntries($input: GetPaymentEntriesInput!) {
    paymentEntries(input: $input) {
      payment {
        id
        orderNo
        finalCost
        paymentMethod
        paymentType
        paymentDuration
        purchaseType
        dueDate
        parentDueDate
        invoice
        receipt
        paymentProof
        parentStatus
        createdAt
        pauseInfo {
          months
          isPlanned
        }
        salesPerson {
          firstName
          lastName
        }
        sale {
          id
          status
          gracePeriod
          isDeferred
          expirationDate
          proposalUrl
          purchaseOrderUrl
          bookingFormUrl
          years
          months
          paymentType
          lot {
            id
            name
            createdAt
            updatedAt
          }
          agent {
            id
            firstName
            lastName
          }
          customer {
            id
            customerId
            firstName
            lastName
            phone
            email
          }
        }
        fulfillmentInfo {
          type
          receipt
          amount
          method
          multiplePaymentReceipts {
            date
            url
            number
          }
        }
        interestInfo {
          dueInterest
          waivedInterestToDate
          lateInstallmentsToDate {
            id
            amount
          }
          overDueInstallments {
            id
            amount
            dueDate
            paymentInterests {
              id
              interestAmount
              approvedAmount
            }
          }
        }
        latePaymentInterestInfo {
          amount
          status
          type
        }
        backdatedPaymentInfo {
          type
          status
          amount
          backdate
          instance
        }
        outstanding {
          amount
          percentage
        }
      }
      entries {
        id
        orderNo
        amount
        paymentMethod
        paymentType
        purchaseType
        paymentDate
        dueDate
        invoice
        receipt
        receipts {
          url
        }
        paymentProof
        paymentProofs {
          date
          url
        }
        status
        isFuture
        commission {
          id
          amount
          status
        }
        paymentInterests {
          id
          interestAmount
          overDueDays
          status
          type
          waivedAmount
          approvedAmount
          paidAmount
          dueInterestInfo {
            amount
            status
          }
          fulfilledInterestInfo {
            amount
            status
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_PAYMENT_INTEREST_PAYING_INTEREST_INFO = gql`
  query PaymentInterestPayingInterestInfo($input: GetPaymentInterestPayingInterestInfoInput!) {
    paymentInterestPayingInterestInfo(input: $input) {
      interestToPay
      interestWaived
      payingInstallments {
        id
        amount
        dueDate
      }
    }
  }
`;

export const GET_PAYMENT_LINK = gql`
  query PaymentLink($key: String!) {
    paymentLink(key: $key) {
      redirectUrl
      payment {
        id
        receipt
      }
    }
  }
`;

export const GET_PAYMENT_VERIFICATIONS = gql`
  query PaymentVerifications($input: GetPaymentVerificationsInput!) {
    paymentVerifications(input: $input) {
      paymentVerifications {
        id
        receivedAt
        purpose
        amount
        stage {
          id
          round
          verifyByDate
          salesPerson {
            status
          }
          tbd {
            status
          }
          finance {
            status
          }
        }
        sale {
          id
          type
          park {
            id
            name
          }
          orderNo
          salesPerson {
            id
            firstName
            lastName
          }
          customer {
            id
            firstName
            lastName
            phone
            email
          }
          additionalCustomers {
            id
            firstName
            lastName
            phone
            email
          }
        }
        payments {
          id
          remarks
          payment
          paymentMethod
          paymentNo
          status
          amount
          paymentProofs {
            url
          }
          rejection {
            id
            reason
          }
          verificationProofs {
            url
          }
        }
        paidBy {
          id
          firstName
          lastName
          phone
          email
        }
      }
      paginationInfo {
        totalItems
        limit
        currentPage
        totalPages
        nextPage
        prevPage
        hasNextPage
        hasPrevPage
      }
    }
  }
`;
