import { ApprovalStatusEnum, IApproval } from './approvalTypes';
import { ICommission } from './commissionTypes';
import { IPaymentInterest, PaymentInterestStatusEnum, PaymentInterestWaiverTypeEnum } from './paymentInterestTypes';
import { ISale } from './salesTypes';
import { IStaff } from './staffTypes';

export enum PaymentPurchaseTypeEnum {
  Booking = 'Booking',
  Purchase = 'Purchase',
}

export enum PaymentModeEnum {
  Online = 'Online',
  Offline = 'Offline',
}

export enum PaymentTermEnum {
  Single = 'Single',
  Multiple = 'Multiple',
}

export enum PaymentStatusEnum {
  Due = 'Due',
  Paid = 'Paid',
  UnPaid = 'UnPaid',
  Late = 'Late',
  UnVerified = 'UnVerified',
  Failed = 'Failed',
  Rejected = 'Rejected',
  Pending = 'Pending',
  Cancelled = 'Cancelled',
  Paused = 'Paused',
  PartiallyPaid = 'PartiallyPaid',
  Deferred = 'Deferred',
  Refunded = 'Refunded',
}

export enum PaymentParentStatusEnum {
  Running = 'Running',
  Paused = 'Paused',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Grace = 'Grace',
  Deferred = 'Deferred',
  Refunded = 'Refunded',
}

export enum PaymentCommissionStatusEnum {
  Pending = 'Pending',
  Due = 'Due',
  Cancelled = 'Cancelled',
  Paid = 'Paid',
}

export enum PaymentTypeEnum {
  Booking = 'Booking',
  Instant = 'Instant',
  Instalment = 'Instalment',
  Cash = 'Cash',
  BookingExtension = 'BookingExtension',
  EditBooking = 'EditBooking',
  AddNominee = 'AddNominee',
  EditNominee = 'EditNominee',
  AddBeneficiary = 'AddBeneficiary',
  EditBeneficiary = 'EditBeneficiary',
  LotResale = 'LotResale',
}

export interface IPaymentProof {
  url: string;
  date: string;
}

interface PauseInfo {
  id: string;
  date: string;
  months: string[];
  isPlanned: boolean;
}

export enum PaymentPaymentTypeEnum {
  Fulfillment = 'Fulfillment',
  PartialFulfillment = 'PartialFulfillment',
  Individual = 'Individual',
  PartialDeferment = 'PartialDeferment', // front-end use only
}
interface FulfillmentInfo {
  type: string;
  receipt: string;
  receiptNo: string;
  amount: number;
  method: string;
  interestAmount: number;
  oldReceipts: Array<{
    date: string;
    url: string;
    number: string;
  }>;
  multiplePaymentReceipts: Array<{
    date: string;
    url: string;
    number: string;
  }>;
}

export type PaymentInfoModeType = 'success' | 'fail' | 'cancel' | undefined;

interface LatePaymentInterestInfo {
  type: PaymentInterestWaiverTypeEnum;
  status: PaymentInterestStatusEnum;
  amount: number;
}

export interface BackdatedPaymentInfo {
  type: PaymentPaymentTypeEnum;
  status: ApprovalStatusEnum;
  amount: number;
  backdate: string;
  instance: string;
}

export interface PaymentInterestInfo {
  lateInstallmentsToDate?: IPayment[];
  waivedInterestToDate: number;
  overDueInstallments?: IPayment[];
  dueInterest: number;
}

export interface PaymentOutstanding {
  amount: number;
  percentage: number;
}

export interface IPayment {
  id: string;
  orderNo: string;
  finalCost: number;
  amount: number;
  paymentMethod: PaymentModeEnum;
  paymentType: PaymentTypeEnum;
  paymentDuration: number;
  purchaseType: PaymentPurchaseTypeEnum;
  dueDate: string;
  parentDueDate: string;
  paymentDate: string;
  invoice: string;
  invoiceNo: string;
  receipt: string;
  receipts: Array<{
    date: string;
    url: string;
    number: string;
  }>;
  receiptNo: string;
  paymentProof: string;
  paymentProofs: IPaymentProof[];
  currency: string;
  status: PaymentStatusEnum;
  parentStatus: PaymentParentStatusEnum;
  gateway: string;
  gatewayResponse: string;
  isParent: boolean;
  parentId: string;
  autoCapture: boolean;
  isFuture: boolean;
  pauseInfo: PauseInfo;
  fulfillmentInfo: FulfillmentInfo;
  latePaymentInterestInfo: LatePaymentInterestInfo;
  backdatedPaymentInfo: BackdatedPaymentInfo;
  entries: IPayment[];
  salesPerson: IStaff;
  sale: ISale;
  commission: ICommission;
  paymentInterests: IPaymentInterest[];
  interestInfo: PaymentInterestInfo;
  approvals: IApproval[];
  outstanding: PaymentOutstanding;
  createdAt: string;
  updatedAt: string;
}
